<template>
	 <div class="table">
		<div class="crumbs">
		    <el-breadcrumb separator="/">
		        <el-breadcrumb-item><i class="el-icon-lx-cascades"></i> 盖楼</el-breadcrumb-item>
		    </el-breadcrumb>
		</div>
		 <div class="container">
			 <div class="handle-box">
			     <el-input v-model="name" placeholder="筛选关键词" class="handle-input mr10"></el-input>
				 <el-button type="primary" icon="search" @click="getData(1)" style="margin-left:10px;">搜索</el-button>
				 <el-button type="primary" @click="handleConfig">设置</el-button>
				 <el-button type="primary" @click="handleTask">任务列表</el-button>
				 <el-button type="primary" @click="handlePrize">奖品列表</el-button>
			 </div>
			 <el-table :data="data" border class="table" ref="multipleTable" @selection-change="handleSelectionChange">
				<el-table-column prop="id" label="编号" width="80" align="center" >
				 </el-table-column>
				 <el-table-column prop="nickname" label="微信昵称" width="100" align="center" >
				  </el-table-column>

				<el-table-column prop="avatarurl"  align="center" label="头像">
					<template   slot-scope="scope">
						<el-popover
								placement="left"
								title=""
								width="500"
								trigger="hover">
							<img :src="scope.row.avatarurl" style="max-width: 100%" />
							<img slot="reference" :src="scope.row.avatarurl" :alt="scope.row.avatarurl" style="max-width: 130px; height: auto; max-height: 100px">
						</el-popover>
						<!--<img :src="scope.row.b_image"  min-width="70" height="70" />-->
					</template>
				</el-table-column>
				  
				<el-table-column prop="tel" label="手机号" width="180" align="center" >
				</el-table-column>
				<el-table-column prop="create_time" label="参与时间" align="center" width="170" sortable>
				</el-table-column>
				<el-table-column prop="number" label="可游戏次数" width="180" align="center" >
				</el-table-column>

				<el-table-column label="操作" align="center" width="180">
<!-- 					<template slot-scope="scope">
						<el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.$index, scope.row, 2)">编辑</el-button>
						<el-button type="text" icon="el-icon-delete" class="red" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
					</template> -->
					<template slot-scope="scope">
					    <el-button type="text" icon="el-icon-edit" @click="handleEdit(scope.row.task_id, scope.row, 2)">参与记录</el-button>
						<el-button type="text" icon="el-icon-edit" @click="handleEdits(scope.row.uid, scope.row, 2)">中奖记录</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
			    <el-pagination background @current-change="handleCurrentChange" layout="prev, pager, next" :total="sumPage">
			    </el-pagination>
			</div>
		 </div>
        <!-- 弹出框 -->
		 <el-dialog v-loading="loading" :visible.sync="editVisible" width="50%">
			  <el-table :data="FloorFormLog" border class="table">
				<el-table-column prop="id" label="编号" width="100" align="center" sortable></el-table-column>
				<el-table-column prop="name" label="任务名" width="180" align="center" ></el-table-column>
				<el-table-column prop="number" label="增加次数" width="100" align="center" ></el-table-column>
				<el-table-column prop="create_time" label="添加时间" width="100" align="center" ></el-table-column>
				<el-table-column prop="desc" label="任务详情" width="200" align="center" ></el-table-column>
			  </el-table>
		  </el-dialog>		 
		  
		<el-dialog title="设置配置"  v-loading='loading' :visible.sync="dialogEmail" width="70%">
			<el-form :model="emailData" :rules="rules" label-width="150px">
				<el-form-item label="活动标题" prop="activity_title">
					<el-input v-model="emailData.activity_title" style="width:180px" placeholder="请输入任务名"></el-input>
				</el-form-item>
                <el-form-item label="活动开始" prop="activity_start_time">
                   <el-date-picker type="date" placeholder="选择日期" v-model="emailData.activity_start_time" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                </el-form-item>
                <el-form-item label="活动结束" prop="activity_end_time">
                   <el-date-picker type="date" placeholder="选择日期" v-model="emailData.activity_end_time" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
                </el-form-item>
				<el-form-item label="赠送次数" prop="first_give_number">
					<el-input v-model="emailData.first_give_number" style="width:180px" placeholder="请输入任务名"></el-input>
				</el-form-item>
                <el-form-item label="活动规则">
                    <vue-ueditor-wrap v-model="emailData.cake_desc" :config="myConfig"></vue-ueditor-wrap>
                </el-form-item>
						
						
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogEmail = false">关 闭</el-button>
				<el-button type="primary" @click="saveConfig()">确 定</el-button>
			</span>
		</el-dialog>
		  
		  <el-dialog title="中奖列表" v-loading="loading" :visible.sync="dialogProsTable" width="70%">
<!-- 			   <el-button type="primary"  @click="handleProsEdit(undefined, undefined, 1)">添加</el-button> -->
			   <el-table :data="prosTableData" border class="table">
				   <el-table-column prop="id" label="编号" width="100" align="center" sortable></el-table-column>
					<el-table-column prop="name" label="奖品名称" width="180" align="center" ></el-table-column>
					<el-table-column prop="create_time" label="获取时间" width="180" align="center" ></el-table-column>
					<el-table-column prop="end_exchange_time" label="到期时间" width="180" align="center" ></el-table-column>
					<el-table-column  label="状态" width="180" align="center" >
						<template slot-scope="scope">
							<span v-if="scope.row.is_use == 1">已使用</span>
							<span v-else>未使用</span>
						</template>
					</el-table-column>
<!-- 				   <el-table-column label="操作" align="center">
						  <template slot-scope="scope">
							  <el-button type="text" icon="el-icon-edit" @click="handleProsEdit(scope.$index, scope.row, 2)">编辑</el-button>
							  <el-button type="text" icon="el-icon-delete" class="red" @click="handleDeletePros(scope.$index, scope.row)">删除</el-button>
						  </template>
				   </el-table-column> -->
			   </el-table>
			   <div class="pagination">
			       <el-pagination background @current-change="handleProsCurrentChange" layout="prev, pager, next" :total="prosSumPage">
			       </el-pagination>
			   </div>
		  </el-dialog>
		  <el-dialog title="游戏记录" v-loading="loading" :visible.sync="UserRecordsTable" width="80%">
<!-- 			  <el-button type="primary"  @click="handleProsCateEdit(undefined, undefined, 1)">添加</el-button> -->
			  <el-table :data="UserRecordsTableData" border class="table">
				   <el-table-column prop="id" label="编号" width="180" align="center" sortable></el-table-column>
				   <el-table-column prop="nickname" label="昵称" width="180" align="center" ></el-table-column>
				   <el-table-column prop="name" label="分享人" width="180" align="center" ></el-table-column>
					<el-table-column prop="score" label="累计得分" width="180" align="center" ></el-table-column>
					<el-table-column prop="star_time" label="开始游戏时间" width="180" align="center" ></el-table-column>
<!-- 				   <el-table-column label="操作" align="center">
						  <template slot-scope="scope">
							  <el-button type="text" icon="el-icon-edit" @click="handleProsCateEdit(scope.$index, scope.row, 2)">编辑</el-button>
							  <el-button type="text" icon="el-icon-delete" class="red" @click="handleDeleteProsCate(scope.$index, scope.row)">删除</el-button>
						  </template>
				   </el-table-column> -->
				   
			  </el-table>
			  <div class="pagination">
			      <el-pagination background @current-change="handleUserRecordsChange" layout="prev, pager, next" :total="UserRecordsSumPage">
			      </el-pagination>
			  </div>
		  </el-dialog>
		  
		  
		  <el-dialog title="任务列表" v-loading="loading" :visible.sync="TaskTable" width="80%">
			  <el-button type="primary"  @click="handleTaskEdit(undefined, undefined, 1)">添加</el-button>
			  <el-table :data="TaskTableData" border class="table">
				    <el-table-column prop="name" label="任务名" width="180" align="center" sortable></el-table-column>
				    <el-table-column prop="key" label="任务标识" width="180" align="center" ></el-table-column>
					<el-table-column prop="b_image" align="center" label="图标">
						<template slot-scope="scope">
							<el-popover
									placement="left"
									title=""
									width="500"
									trigger="hover">
								<img :src="scope.row.b_image" style="max-width: 100%" />
								<img slot="reference" :src="scope.row.b_image" :alt="scope.row.b_image" style="max-width: 130px; height: auto; max-height: 100px">
							</el-popover>
						</template>
					</el-table-column>
					<el-table-column prop="btn_text" label="按钮文字" width="180" align="center" ></el-table-column>
				    <el-table-column  label="状态" width="180" align="center" >
						<template slot-scope="scope">
							<span v-if="scope.row.status == 1">显示</span>
							<span v-else>隐藏</span>
						</template>
					</el-table-column>
				   <el-table-column label="操作" align="center">
						  <template slot-scope="scope">
							  <el-button type="text" icon="el-icon-edit" @click="handleTaskEdit(scope.$index, scope.row, 2)">编辑</el-button>
							  <el-button type="text" icon="el-icon-delete" class="red" @click="handleDeleteTask(scope.$index, scope.row)">删除</el-button>
						  </template>
				   </el-table-column>
			  </el-table>
		  </el-dialog>		
			<el-dialog title="添加任务" v-loading="loading" ref="TaskForm" :visible.sync="taskFormAdd" width="50%">
				<el-form ref="TaskForm" :rules="ruless" :model="TaskForm" label-width="130px">
				    <el-form-item label="任务名" prop="name">
				        <el-input v-model="TaskForm.name" style="width:180px" placeholder="请输入任务名"></el-input>
				    </el-form-item>
					<el-form-item label="任务分数" prop="number">
					    <el-input v-model="TaskForm.number" style="width:180px" placeholder="请输入任务分数"></el-input>
					</el-form-item>
					<el-form-item label="按钮文字" prop="btn_text">
					    <el-input v-model="TaskForm.btn_text" style="width:180px" placeholder="请输入按钮文字"></el-input>
					</el-form-item>
					<el-form-item label="显示图片">
					    <el-upload
					            class="avatar-uploader"
					            name="image"
					            with-credentials
					            list-type="picture-card"
					            :data="{id:this.TaskForm.imgid}"
					            :action="uploadUrl()"
					            :on-error="uploadError"
					            :on-success="handleAvatarSuccess"
					            :before-upload="beforeAvatarUpload"
					            :on-progress="uploading"
					            :show-file-list="false"
					            :auto-upload="true"
					            enctype="multipart/form-data">
					        <img v-if="TaskForm.b_image" :src="TaskForm.b_image" class="avatar">
					        <i v-else class="el-icon-plus"></i>
					    </el-upload>
					    <span style="color:red">建议尺寸200*200</span>
					</el-form-item>
					<el-form-item label="任务标识" prop="key">
					    <el-input v-model="TaskForm.key" style="width:180px" placeholder=""></el-input>
						<span>填写后不可更改</span>
					</el-form-item>
					<el-form-item label="任务地址" prop="url">
					    <el-input v-model="TaskForm.url" style="width:180px" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="任务说明" prop="desc">
					    <el-input v-model="TaskForm.desc" style="width:180px" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="限制次数" prop="limit_number">
					    <el-input v-model="TaskForm.limit_number" style="width:180px" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="任务排序" prop="sort">
					    <el-input v-model="TaskForm.sort" style="width:180px" placeholder=""></el-input>
						<span>排序值越小，排位越靠前</span>
					</el-form-item>
					<el-form-item label="限制类型" prop="limit_status">
						<el-radio v-model="TaskForm.limit_status"  label="0" >总次数</el-radio>
						<el-radio v-model="TaskForm.limit_status" label="1">当天计算</el-radio>
					</el-form-item>
					<el-form-item label="状态" prop="status">
						<el-radio v-model="TaskForm.status"  label="0" >隐藏</el-radio>
						<el-radio v-model="TaskForm.status" label="1">显示</el-radio>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
				    <el-button @click="taskFormAdd = false">关 闭</el-button>
				    <el-button type="primary" @click="saveTask('TaskForm')">确 定</el-button>
				</span>
			</el-dialog>
			

		  
		  <el-dialog title="奖品列表" v-loading="loading" :visible.sync="PrizeTable" width="80%">
			  <el-button type="primary"  @click="handlePrizeEdit(undefined, undefined, 1)">添加</el-button>
			  <el-table :data="PrizeTableData" border class="table">
				    <el-table-column prop="name" label="奖品名称" width="180" align="center" sortable></el-table-column>
					<el-table-column prop="b_image" align="center" label="奖品图">
						<template slot-scope="scope">
							<el-popover
									placement="left"
									title=""
									width="500"
									trigger="hover">
								<img :src="scope.row.b_image" style="max-width: 100%" />
								<img slot="reference" :src="scope.row.b_image" :alt="scope.row.b_image" style="max-width: 130px; height: auto; max-height: 100px">
							</el-popover>
						</template>
					</el-table-column>
				    <el-table-column prop="stock" label="奖品库存" width="180" align="center" ></el-table-column>
				    <el-table-column  label="状态" width="180" align="center" >
						<template slot-scope="scope">
							<span v-if="scope.row.status == 1">显示</span>
							<span v-else>隐藏</span>
						</template>
					</el-table-column>
				   <el-table-column label="操作" align="center">
						  <template slot-scope="scope">
							  <el-button type="text" icon="el-icon-edit" @click="handlePrizeEdit(scope.$index, scope.row, 2)">编辑</el-button>
							  <el-button type="text" icon="el-icon-delete" class="red" @click="handleDeletePrize(scope.$index, scope.row)">删除</el-button>
						  </template>
				   </el-table-column>
			  </el-table>
		  </el-dialog>		
			<el-dialog title="添加奖品" v-loading="loading" ref="PrizeForm" :visible.sync="prizeFormAdd" width="50%">
				<el-form ref="PrizeForm" :rules="rulesss" :model="PrizeForm" label-width="130px">
				    <el-form-item label="奖品名称" prop="name">
				        <el-input v-model="PrizeForm.name" style="width:180px" placeholder="请输入奖品名称"></el-input>
				    </el-form-item>
					<el-form-item label="价值" prop="value">
					    <el-input v-model="PrizeForm.value" style="width:180px" placeholder="请输入价值"></el-input>
					</el-form-item>
					<el-form-item label="奖品缩略图">
					    <el-upload
					            class="avatar-uploader"
					            name="image"
					            with-credentials
					            list-type="picture-card"
					            :data="{id:this.PrizeForm.imgid}"
					            :action="uploadUrl()"
					            :on-error="uploadError"
					            :on-success="handleAvatarSuccess2"
					            :before-upload="beforeAvatarUpload"
					            :on-progress="uploading"
					            :show-file-list="false"
					            :auto-upload="true"
					            enctype="multipart/form-data">
					        <img v-if="PrizeForm.b_image" :src="PrizeForm.b_image" class="avatar">
					        <i v-else class="el-icon-plus"></i>
					    </el-upload>
					    <span style="color:red">建议尺寸200*200</span>
					</el-form-item>
					<el-form-item label="奖品库存" prop="stock">
					    <el-input v-model="PrizeForm.stock" style="width:180px" placeholder="请输入奖品库存"></el-input>
					</el-form-item>
					<el-form-item label="弹出层数" prop="pop_number">
					    <el-input v-model="PrizeForm.pop_number" style="width:180px" placeholder=""></el-input>
					</el-form-item>
					<el-form-item label="兑换结束时间" prop="end_exchange_time">
					   <el-date-picker type="date" placeholder="选择日期" v-model="PrizeForm.end_exchange_time" value-format="yyyy-MM-dd" style="width: 100%;"></el-date-picker>
					</el-form-item>
<!-- 					<el-form-item label="奖品类型" prop="type">
						<el-radio v-model="PrizeForm.type"  label="0" >优惠券</el-radio>
						<el-radio v-model="PrizeForm.type" label="1">自定义</el-radio>
					</el-form-item> -->
					<el-form-item label="状态" prop="status">
						<el-radio v-model="PrizeForm.status"  label="0" >隐藏</el-radio>
						<el-radio v-model="PrizeForm.status" label="1">显示</el-radio>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
				    <el-button @click="prizeFormAdd = false">关 闭</el-button>
				    <el-button type="primary" @click="savePrize('PrizeForm')">确 定</el-button>
				</span>
			</el-dialog>
			
			
			<!-- 删除提示框 -->
			<el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
			    <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
			    <span slot="footer" class="dialog-footer">
			        <el-button @click="delVisible = false">取 消</el-button>
			        <el-button type="primary" @click="deleteRow(delMode)">确 定</el-button>
			    </span>
			</el-dialog>			
			
			
			

	</div>
</template>

<script>
	import 'quill/dist/quill.core.css';
	import 'quill/dist/quill.snow.css';
	import 'quill/dist/quill.bubble.css';
	import {quillEditor, Quill} from 'vue-quill-editor'
	import {container, ImageExtend, QuillWatch} from 'quill-image-extend-module';
	Quill.register('modules/ImageExtend', ImageExtend)
	
	export default {
		name: 'basetable',
		components: {
		    quillEditor
		},
		 data() {
			 return{
				 myConfig: {
				     // 编辑器不自动被内容撑高
				     autoHeightEnabled: false,
				     // 初始容器高度
				     initialFrameHeight: 600,
				     // 初始容器宽度
				     initialFrameWidth: '90%',
				     zIndex: 9999,
				     // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
				     serverUrl: this.$api.editorUrl,
				     // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
				     UEDITOR_HOME_URL: '/ueditor/'
				 },
				 tableData: [],
				 AddOrSave:'',  //1表示添加，2表示更新
				 TaskAddOrSave:'',  //1表示添加，2表示更新
				 PrizeAddOrSave:'',  //1表示添加，2表示更新
				 cur_page: 1,  //当前页
				 number: 10,  //每页显示条数
				 sumPage:10,  //总页数
				 pros_cur_page:1,
				 user_records_page:1,
				 task_page:1,
				 prize_page:1,
				 prosSumPage:10, 
				 UserRecordsSumPage:10,
				 TaskSumPage:10,
				 PrizeSumPage:10,
				 prosTableData:[],
				 prosCateTableData:[],
				 UserRecordsTableData:[],
				 TaskTableData:[],
				 PrizeTableData:[],
				 multipleSelection: [],
				 dialogProsTable:false,
				 UserRecordsTable:false,
				 TaskTable:false,
				 PrizeTable:false,
				 dialogAddProsCate:false,
				 taskFormAdd:false,
				 prizeFormAdd:false,
				 dialogAddPros:false,
				 editVisible: false,
				 start_time:'',
				 end_time:'',
				 name:'',
				 mobile:'',
				 loading:false, //加载中
				 del_list: [],
				 activeName: 'first',  //默认选中tab
				 TaskForm:{
					 id:'',
					 name:'',
					 key:'',
					 icon:'',
					 number:'',
					 btn_text:'',
					 desc:'',
					 limit_status:0,
					 limit_number:'',
					 sort:0,
					 url:'',
					 status:0,
					 b_image:'',
				 },
				 PrizeForm:{
					 id:'',
					 name:'',
					 value:'',
					 image:'',
					 stock:'',
					 pop_number:'',
					 end_exchange_time:'',
					 status:0,
					 b_image:'',
				 },				 
				 form: {
				     id: '',
				     ordernumber: '',
				     nickname: '',
				     name: '',

				 },
				 FloorFormLog: {
				     id: '',
				     name: '',
				     desc: '',
				     number: '',
					 create_time: '',
				 },				 
				 detailList: [],
				 prosCateForm:{
					 id:'',
					 cate_name:'',
					 is_show:1,
					 sort:0,
					 choose_pro_max:0,
					 time_peri:0
				 },
				 delMode:'Star',
				 delVisible:false,
				 dialogEmail:false,
				 appointid:'',
				 rules: {
					title: [
                        { required: true, message: '请输入标题', trigger: 'blur' }
                    ],
                    score: [
                        { required: true, message: '请输入分值', trigger: 'blur' }
                    ],                   
                    // need_score: [
                    //     { required: true, message: '请输入分数', trigger: 'blur' }
                    // ],
                    count:[
                        { required: true, message: '请输入总数', trigger: 'blur' }
                    ],
                    width:[
                        { required: true, message: '请输入星星尺寸', trigger: 'blur' }
                    ],
					show_img: [
					    { required: true, message: '请上传显示图片', trigger: 'blur' }
					],
					click_img: [
					    { required: true, message: '请上传点击图片', trigger: 'blur' }
					],                   
					start_time: [
					    { required: true, message: '活动开始', trigger: 'blur' }
					],
					end_time:[
					    { required: true, message: '活动结束', trigger: 'blur' }
					],

                 },
				 ruless: {
					name: [
                        { required: true, message: '请输入任务名', trigger: 'blur' }
                    ],
					number: [
                        { required: true, message: '请输入任务分数', trigger: 'blur' }
                    ],
					btn_text: [
					    { required: true, message: '请输入按钮文字', trigger: 'blur' }
					],
					icon: [
					    { required: true, message: '请上传按钮图', trigger: 'blur' }
					],
					key: [
					    { required: true, message: '请输入任务标识', trigger: 'blur' }
					],

                 },
				 rulesss: {
					name: [
                        { required: true, message: '请输入奖品名称', trigger: 'blur' }
                    ],
					value: [
                        { required: true, message: '请输入奖品价值', trigger: 'blur' }
                    ],
					imgid: [
					    { required: true, message: '请上传奖品图', trigger: 'blur' }
					],
					stock: [
					    { required: true, message: '请输入奖品库存', trigger: 'blur' }
					],
					end_exchange_time: [
					    { required: true, message: '请输入兑换结束时间', trigger: 'blur' }
					],
					pop_number: [
					    { required: true, message: '请输入弹出层数', trigger: 'blur' }
					],
                 },				 
				 emailData:{
					 activity_title:'',
					 president_fir:'',
					 president_fir_img:'',
				 }
			 }
		 },
		 created() {
		     this.getData();
		
		 },
		 computed: {
		     data() {
		         return this.tableData.filter((d) => {
		             let is_del = false;
		             for (let i = 0; i < this.del_list.length; i++) {
		                 if (d.name === this.del_list[i].name) {
		                     is_del = true;
		                     break;
		                 }
		             }
		             return d;
		         })
		     }
		 },
		  methods: {
			  //设置上传图片接口地址
			  uploadUrl(){
			      var url=this.$api.uploadUrl + "/Images/upload";
			      return url
			  },
			  handleClick(tab, event) {
			      // console.log(tab, event);
			  },
			  //图片上传之前
			  beforeAvatarUpload(file){
			      // console.log(file);
			  },
			  //正在上传中
			  uploading(event, file, fileList){
			      // console.log(event);
			      // console.log(file);
			      // console.log(fileList);
			  },
			  //图片上传失败
			  uploadError(err){
			      this.$message.error(err.msg);
			  },
			  //图片上传成功
			  handleAvatarSuccess(res, file){
			      
			      this.TaskForm.imgid=res.data;
			      this.TaskForm.b_image = URL.createObjectURL(file.raw);
				  this.handleTask();

			      //this.getData();
				  //this.handleConfig();
			      this.$message.success(res.msg);
			  },
			  //图片上传成功2
			  handleAvatarSuccess2(res, file){
			      
			      this.PrizeForm.imgid=res.data;
			      this.PrizeForm.b_image = URL.createObjectURL(file.raw);
				  this.handlePrize();

			      //this.getData();
				  //this.handleConfig();
			      this.$message.success(res.msg);
			  },

			  getData(){
				  var params=this.$qs.stringify({
				      name: this.name,
				      mobile: this.mobile,
					  pageIndex: this.cur_page,
					  number: this.number,
					  activity_type:3,
				  });
				  // console.log(params);
				  this.$api.post('Floor/index', params, res => {
				      this.tableData = res.data.list;
				      this.sumPage = res.data.sumPage*10;
				      this.cur_page = res.data.currentPage;
				      console.log(res);
				  }, err => {
				      this.tableData = [];
				      this.sumPage = 1*10;
				      this.cur_page = 1;
				      this.$message.error(err.msg);
				  });
			  },
			handleEdit(index, row, status) {

				var params=this.$qs.stringify({
					id: row.id,
				});

			  	this.$api.post('Floor/showLog', params, res => {
			  	   this.FloorFormLog = res.data.list;
				   this.editVisible = true;
			  	});


			},
            saveEdit(formName) {// 保存编辑
                // this.$set(this.tableData, this.idx, this.form);
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.editVisible = false;
                        var params=null;
                        //1表示添加，2表示更新
                        if(this.AddOrSave==1){
                            params=this.$qs.stringify({
                                title: this.form.title,
                                score: this.form.score,
                                count: this.form.count,
								width:this.form.width,
                                show_img: this.form.show_img,
                                click_img: this.form.click_img,
                                status: this.form.status ? 1 : 0,

                            });
                        }else{
                            params=this.$qs.stringify({
                                id: this.form.id,
                                title: this.form.title,
                                score: this.form.score,
                                count: this.form.count,
								width:this.form.width,
                                show_img: this.form.show_img,
								show_img: this.form.show_img,
                                click_img: this.form.click_img,
                                status: this.form.status ? 1 : 0,

                            });
                        }

                        this.$api.post('StarPowder/addStarPowder', params, res => {
                            this.getData();
                            this.$message.success(res.msg);


                        }, err => {
                            this.$message.error(err.msg);
                        });
                    }else{
                        console.log("请填写所需数据");
                        return false;
                    }
                });
                // this.$message.success(`修改第 ${this.idx+1} 行成功`);
            },
			  handleEdits(index, row, status){
				var params=this.$qs.stringify({
					  uid: index,
					  number: this.number,
					  pageIndex: this.pros_cur_page,
				});
			  	this.$api.post('Floor/showPrizes', params, res => {
			  	    this.prosTableData = res.data.list;
					this.dialogProsTable = true;
					this.prosSumPage = res.data.sumPage*10;
					this.pros_cur_page = res.data.currentPage;
			  	}, err => {
				      this.prosTableData = [];
				      this.prosSumPage = 1*10;
				      this.pros_cur_page = 1;
				      this.$message.error(err.msg);
				  });
			  },
			  handleProsCate(){	//游戏用户
				var params=this.$qs.stringify({
					  number: this.number,
					  pageIndex: this.user_records_page,
				});
			  	this.$api.post('StarPowder/UserRecords', params, res => {
			  	   this.UserRecordsTableData = res.data.list;
				   this.UserRecordsTable = true;
				   this.UserRecordsSumPage = res.data.sumPage*10;
				   this.user_records_page = res.data.currentPage;

			  	}, err => {
				    this.UserRecordsTableData = [];
				    this.UserRecordsSumPage = 1*10;
				    this.user_records_page = 1;
				    this.$message.error(err.msg);
				  });
			  },			  
			  handleTask(){	//任务列表
				var params=this.$qs.stringify({
					  number: this.number,
					  pageIndex: this.user_records_page,
				});
			  	this.$api.post('FloorTask/index', params, res => {
					this.TaskTableData = res.data.list;
					this.TaskTable = true;
					this.TaskSumPage = res.data.sumPage*10;
					this.task_page = res.data.currentPage;

			  	}, err => {
				    this.TaskTableData = [];
				    this.TaskSumPage = 1*10;
				    this.task_page = 1;
				    this.$message.error(err.msg);
				  });
			  },
			  handleTaskEdit(index, row, status){
				   if(status  ==  1){
						this.TaskForm = {
							 name:null,
							 key:null,
							 icon:null,
							 number:null,
							 btn_text:null,
							 desc:null,
							 limit_status:'1',
							 limit_number:null,
							 sort:0,
							 url:null,
							 status:'1',
							 imgid: null,
							 b_image: null,
						};
						//console.log(this.TaskForm);
				   }else{
					  var item = this.TaskTableData[index];

						this.TaskForm ={
						  id:item.id,
						  name:item.name,
						  key:item.key,
						  icon:item.icon,
						  number:item.number,
						  btn_text:item.btn_text,
						  desc:item.desc,
						  limit_status:item.limit_status,
						  limit_number:item.limit_number,
						  sort:item.sort,
						  url:item.url,
						  status:item.status,
						  imgid: item.imgid,
						  b_image: item.b_image,
						  
						}
					  //console.log(this.prosCateForm)
				   }
					this.taskFormAdd = true
			  },
			  saveTask(formName){
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if(!this.TaskForm.imgid){
							this.$message.error('请上传图片');
							return false;
						}
					   var params=this.$qs.stringify({
						   id:this.TaskForm.id,
						   name:this.TaskForm.name,
						   key:this.TaskForm.key,
						   icon:this.TaskForm.icon,
						   number:this.TaskForm.number,
						   btn_text:this.TaskForm.btn_text,
						   desc:this.TaskForm.desc,
						   limit_status:this.TaskForm.limit_status,
						   limit_number:this.TaskForm.limit_number,
						   sort:this.TaskForm.sort,
						   url:this.TaskForm.url,
						   status:this.TaskForm.status,
						   imgid:this.TaskForm.imgid,
					   })
					  this.$api.post('FloorTask/add',params,res=>{
						  this.taskFormAdd = false
						  this.handleTask()
						  this.$message.success(res.msg);
					  },err=>{
						   this.$message.error(err.msg);
					  });
                    }else{
                        console.log("请填写所需数据");
                        return false;
                    }				  
				  
				});  
			  },
			  handlePrize(){	//奖品列表
				var params=this.$qs.stringify({
					  number: this.number,
					  pageIndex: this.user_records_page,
				});
			  	this.$api.post('FloorPrizes/prizesIndex', params, res => {
					this.PrizeTableData = res.data.list;
					this.PrizeTable = true;
					this.PrizeSumPage = res.data.sumPage*10;
					this.prize_page = res.data.currentPage;

			  	}, err => {
				    this.PrizeTableData = [];
				    this.PrizeSumPage = 1*10;
				    this.prize_page = 1;
				    this.$message.error(err.msg);
				  });
			  },
			  handlePrizeEdit(index, row, status){
				   if(status  ==  1){
						this.PrizeForm = {
							 name:null,
							 value:null,
							 image:null,
							 stock:null,
							 end_exchange_time:null,
							 status:'0',
							 imgid: null,
							 b_image: null,
						};
						console.log(this.PrizeForm);
				   }else{
					  var item = this.PrizeTableData[index];

						this.PrizeForm ={
						  id:item.id,
						  name:item.name,
						  value:item.value,
						  image:item.image,
						  stock:item.stock,
						  pop_number:item.pop_number,
						  end_exchange_time:item.end_exchange_time,
						  status:item.status,
						  imgid:item.imgid,
						  b_image:item.b_image,
						}
						console.log(this.PrizeForm);
				   }
					this.prizeFormAdd = true
			  },
			  savePrize(formName){
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if(!this.PrizeForm.imgid){
							this.$message.error('请上传奖品图片');
							return false;
						}
					   var params=this.$qs.stringify({
						   id:this.PrizeForm.id,
						   name:this.PrizeForm.name,
						   value:this.PrizeForm.value,
						   image:this.PrizeForm.image,
						   stock:this.PrizeForm.stock,
						   btn_text:this.PrizeForm.btn_text,
						   pop_number:this.PrizeForm.pop_number,
						   end_exchange_time:this.PrizeForm.end_exchange_time,
						   status:this.PrizeForm.status,
						   imgid:this.PrizeForm.imgid,
					   })
					  this.$api.post('FloorPrizes/addPrize',params,res=>{
						  this.prizeFormAdd = false
						  this.handlePrize()
						  this.$message.success(res.msg);
					  },err=>{
						   this.$message.error(err.msg);
					  });
                    }else{
                        console.log("请填写所需数据");
                        return false;
                    }				  
				  
				});  
			  },
			  handleDelete(index, row) {
			      this.idx = index
			      this.form = row
			      this.delVisible = true
				  this.delMode = 'Star'
			  	
			  },
			  handleDeleteTask(index,row){
				  this.TaskForm = row
				  this.delVisible = true
				  this.delMode = 'task'
			  },
			  handleDeletePrize(index,row){
				  this.PrizeForm = row
				  this.delVisible = true
				  this.delMode = 'prize'

			  },			  
			  deleteRow(delmode){

				  var url = ''
				  var id = ''
					if(delmode == 'Star'){
						url = 'StarPowder/delStarPowder';
						id = this.form.id;
					}else if(delmode == 'task'){
						url = 'FloorTask/del';
						id = this.TaskForm.id;
					}else if(delmode == 'prize'){
						url = 'FloorPrizes/del';
						id = this.PrizeForm.id;
					}

					var params=this.$qs.stringify({
						id: id
				  });
				  this.$api.post(url, params, res => {
					  //console.log(res);
					//this.handlePros();

					if(delmode == 'Star'){
						this.getData();
					}else if(delmode == 'task'){
						this.handleTask();
					}else if(delmode == 'prize'){
						this.handlePrize();
					}
					this.$message.success(res.msg);
					
					//this.$message.success(res.msg+res.data+"条数据");
				  }, err => {
				      this.$message.error(err.msg);
				  });
				  this.delVisible = false;
			  },
			  handleConfig(){
					var params = this.$qs.stringify({

					})
					this.dialogEmail = true
				    this.$api.post('Floor/showSetting', params,res=>{
						this.emailData = res.data.list
						//console.log(emailData);
				  },err => {
				    this.emailData = []
				    this.$message.error(err.msg);
				 });
			  },
			  saveConfig(){
				  var params=this.$qs.stringify({
						activity_start_time:this.emailData.activity_start_time,
						activity_end_time:this.emailData.activity_end_time,
						activity_title:this.emailData.activity_title,
						first_give_number:this.emailData.first_give_number,
						cake_desc:this.emailData.cake_desc,
				  });

				  this.$api.post('Floor/setting', params, res => {
						this.dialogEmail =  false
						this.$message.success(res.msg);
				  }, err => {
				      this.$message.error(err.msg);
				  });
			  },
			  // 分页导航
			  handleCurrentChange(val) {
			      this.cur_page = val;
			      this.getData();
			  },
			  handleProsCurrentChange(val){
				  this.pros_cur_page = val
				   //this.handlePros()
			  },
			  handleUserRecordsChange(val){
				  this.user_records_page = val
				   this.handleProsCate()
			  },
			  handleSelectionChange(val) {
			      this.multipleSelection = val;
			  }
			
		  }
	}
</script>

<style scoped>
	.handle-box {
	    margin-bottom: 20px;
	}
	
	.handle-select {
	    width: 120px;
	}
	
	.handle-input {
	    width: 300px;
	    display: inline-block;
	}
	.del-dialog-cnt{
	    font-size: 16px;
	    text-align: center
	}
	.table{
	    width: 100%;
	    font-size: 14px;
	}
	.red{
	    color: #ff0000;
	}
</style>
